/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@ui5/webcomponents-react';
import history from './common/history';
import Consent from './features/consent';
import ConsentDetail from './features/consent/Detail';
import MessageToast from 'src/features/common/toast.plugin';

const App = () => {
  return (
    <ThemeProvider>
      <Router history={history}>
        <Switch>
          <Redirect from="/" to="/consent" exact />
          <Route path="/consent" component={Consent} exact />
          <Route path="/consent/product/:id" component={ConsentDetail} exact />
        </Switch>
      </Router>
      <MessageToast key="default-message-toast" />
    </ThemeProvider>
  );
};

export default App;
