import React, { useState } from 'react';
import {
  Dialog,
  Button,
  ButtonDesign,
  Label,
  RatingIndicator,
  Input,
  TextArea,
} from '@ui5/webcomponents-react';
import { useTranslation, MessageToast } from 'src/common/eureka';
import { createConsentReview } from './axios';
import dayjs from 'dayjs';

export default React.forwardRef(({ productInfo, userId, onOk, onClose }, ref) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({});

  return (
    <Dialog
      ref={ref}
      stretch={false}
      headerText={t('Product Review')}
      footer={
        <div style={{ margin: '5px', textAlign: 'right', width: '100%' }}>
          <Button
            data-testid="widget-add-next"
            style={{ marginRight: '10px' }}
            design={ButtonDesign.Emphasized}
            onClick={() => {
              createConsentReview({
                ...value,
                productName: productInfo?.name,
                createBy: userId,
                createOn: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
              }).then(() => {
                MessageToast.success(t('Review created.'));
                onOk && onOk();
              });
            }}
          >
            {t('Review')}
          </Button>
          <Button style={{ marginRight: '0' }} design={ButtonDesign.Transparent} onClick={onClose}>
            {t('Cancel')}
          </Button>
        </div>
      }
    >
      <div
        style={{
          padding: '20px',
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          <Label showColon>{t('Rating')}</Label>
          <div>
            <RatingIndicator onChange={(e) => setValue({ ...value, rating: e.target.value })} />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Label showColon required>
            {t('Title')}
          </Label>
          <div>
            <Input
              style={{ width: '100%' }}
              onChange={(e) => setValue({ ...value, title: e.target.value })}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Label showColon required>
            {t('Review Text')}
          </Label>
          <div>
            <TextArea
              style={{ width: '100%' }}
              rows={3}
              onChange={(e) => setValue({ ...value, text: e.target.value })}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
});
