import { useState, useEffect } from 'react';
import usePagination from './usePagination';

export default function useList(reqFn, defaultParams = {}, lazy = false) {
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    ...defaultParams,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { pageNo, pageSize, total, setTotal } = usePagination(data.length, defaultParams.pageSize);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const fetchData = () => {
      setIsError(false);
      setIsLoading(true);
      const _params = { pageNo: defaultParams.pageNo || 1, pageSize, ...params };
      _params.pageNo === 1 && data.length && setData([]);
      reqFn({ ..._params })
        .then(res => {
          if (didCancel) return;
          res = res?.data;
          setData(data => (Array.isArray(res) ? res : data.concat(res?.content || [])));
          setTotal(Array.isArray(res) ? res.length : res?.totalElements);
        })
        .catch(error => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (!lazy || mounted) fetchData();

    setMounted(true);

    return () => {
      didCancel = true;
    };
  }, [params]);

  const onLoadMore = () => {
    pageNo && setParams({ ...params, pageNo });
  };

  const doFetch = para => {
    setParams(para || { ...params, pageNo: 1 });
  };

  return {
    data,
    doFetch,
    isLoading: isLoading && !(params.pageNo > 1),
    isLoadingMore: isLoading && params.pageNo > 1,
    onLoadMore,
    pagination: { pageNo, pageSize, total },
    isError,
  };
}
