export const headerPanelStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
};

export const buttonPanelStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '50%',
};

export const titlePanelStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '50%',
};

export const titleStyle = {
  fontSize: '18px',
};

export const disabledColor = {
  color: 'grey',
};

export function analyticTableStyle() {
  return {
    '& > div > header > div:first-child': {
      borderLeft: '0',
    },
    '& > div > header > div': {
      // each cell doesn't have border now
      border: 'none',
    },
    '& > div > div > div > div > div:first-child': {
      borderLeft: '0',
      borderRight: '0',
    },
    // '& > div > div > div > div > div': {
    // each cell doesn't have border now
    // border: 'none',
    // },
    // '& > div > div > div > div > div:last-child': {
    //   // each cell doesn't have border now
    //   borderRight: '1px solid var(--sapList_BorderColor)',
    // },
    '& > div > div > div > div > div > div': {
      borderRight: '0px',
    },
    '& > div > div > div > div > div > div:first-child': {
      borderLeft: '0px',
      // width: '40px !important',
    },
    '& > div > div > div > div > div > div:last-child': {
      // Currently having to specify an important width here in order
      // to overwrite spacing that cuts off icon button column.
      // width: '65px !important',
    },
    '& > div > div > header > div': {
      borderRight: '0px',
      height: '47px',
    },
    '& > div > div > header': {
      height: '47px',
    },
    '& > div > div > header > div:first-child': {
      borderLeft: '0px',
      // disable select on the header checkbox
      pointerEvents: 'none',
      width: '40px !important',
    },
    // '& > div > div > header > div:first-child > div > div > span > ui5-checkbox': {
    //   // disable checkbox
    //   pointerEvents: 'none',
    //   opacity: 0.3,
    // },
    '& > div > div > header > div:last-child > div': {
      width: 'auto !important',
    },
    // '& div > div > div > div > div > div:first-child > ui5-checkbox': {
    //   // disable checkbox
    //   pointerEvents: 'none',
    //   opacity: 0.3,
    // },
  };
}
