import eureka from 'eureka';
import qs from 'qs';
const { axiosCreate } = eureka.HttpWrapper;

export const consentAxios = axiosCreate({
  baseURL: '/api/consent-repository-demo/business/v1/consent',
  timeout: 10000,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
}).instance;

const fetchConsentProductList = (params) => {
  return consentAxios.get('/products', { params });
};

const createConsentAuth = (params) => {
  return consentAxios.post('/create', params);
};

const createConsentReview = (params) => {
  return consentAxios.post('/review', params);
};

const getAuthorizationTemplate = (params) => {
  return consentAxios.get('/consentTemplate', { params });
};

const checkReviewed = (name) => {
  return consentAxios.get('/reviewed', { params: { name } });
};

export {
  fetchConsentProductList,
  createConsentAuth,
  createConsentReview,
  getAuthorizationTemplate,
  checkReviewed,
};
