import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  Button,
  ButtonDesign,
  Label,
  CheckBox,
  Panel,
  MessageBox,
} from '@ui5/webcomponents-react';
import { useTranslation, eventBus } from 'src/common/eureka';
import { getAuthorizationTemplate, createConsentAuth } from './axios';

export default React.forwardRef(({ userId, onOk, onClose }, ref) => {
  const { t } = useTranslation('consent-repository-demo');
  const detailDialogRef = useRef();
  const [template, setTemplate] = useState({});
  const [value, setValue] = useState({});
  const [privacyMessageBoxOpen, setPrivacyMessageBoxOpen] = useState(false);

  useEffect(() => {
    getAuthorizationTemplate().then((res) => {
      setTemplate(res.data || {});
    });
  }, []);

  const expandDetail = (e) => {
    e.preventDefault();
    detailDialogRef.current.show();
  };

  const expandPrivacyStatement = (e) => {
    e.preventDefault();
    setPrivacyMessageBoxOpen(true);
  };

  return (
    <>
      <Dialog
        ref={ref}
        stretch={false}
        headerText={t('Grant Consent')}
        footer={
          <div style={{ margin: '5px', textAlign: 'right', width: '100%' }}>
            <Button
              data-testid="widget-add-next"
              style={{ marginRight: '10px' }}
              design={ButtonDesign.Emphasized}
              disabled={!(value.confirmConsentQuestion && value.confirmPrivacyStatement)}
              onClick={() => {
                createConsentAuth({
                  templateName: template.templateName,
                  dataSubjectId: userId,
                  dataSubjectTypeName: 'email',
                }).then(() => {
                  onOk && onOk();
                });
              }}
            >
              {t('Save')}
            </Button>
            <Button
              style={{ marginRight: '0' }}
              design={ButtonDesign.Transparent}
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
          </div>
        }
      >
        <div
          style={{
            padding: '20px',
          }}
        >
          <div style={{ margin: '10px 0' }}>
            <Label showColon style={{ marginRight: '10px' }}>
              {t('Username')}
            </Label>
            <Label>{userId}</Label>
          </div>
          <div>
            <CheckBox
              text={template.consentQuestion}
              onChange={() =>
                setValue({ ...value, confirmConsentQuestion: !value.confirmConsentQuestion })
              }
            />
          </div>
          <div style={{ paddingLeft: '50px' }}>
            <a href="##" onClick={expandDetail}>
              {t('Detail')}
            </a>
          </div>
          <div>
            <CheckBox
              text={t('I have read and accept the privacy statement.')}
              onChange={() =>
                setValue({ ...value, confirmPrivacyStatement: !value.confirmPrivacyStatement })
              }
            />
          </div>
          <div style={{ paddingLeft: '50px' }}>
            <a href="##" onClick={expandPrivacyStatement}>
              {t('Privacy Statement')}
            </a>
          </div>
        </div>
      </Dialog>
      <Dialog
        ref={detailDialogRef}
        stretch={false}
        headerText={t('Detail')}
        footer={
          <div style={{ margin: '5px', textAlign: 'right', width: '100%' }}>
            <Button
              design={ButtonDesign.Transparent}
              onClick={() => detailDialogRef.current.close()}
            >
              {t('Close')}
            </Button>
          </div>
        }
      >
        <div
          style={{
            padding: '20px',
          }}
        >
          <Panel headerText={template.consentQuestion}>{template.consentExplanatoryText}</Panel>
          <Panel headerText={t('Why do I have to grant my consent?')}>
            {template.consentConsequence}
          </Panel>
        </div>
      </Dialog>
      <MessageBox
        open={privacyMessageBoxOpen}
        title={t('Privacy Statement')}
        type="Information"
        actions={[t('Close')]}
        style={{ width: '500px' }}
        onClose={() => setPrivacyMessageBoxOpen(false)}
      >
        {template.dataPrivacyStatement}
      </MessageBox>
    </>
  );
});
