import { useState, useMemo } from 'react';

export default function usePagination(currentTotal = 0, pageSize = 10) {
  const [total, setTotal] = useState(0);
  const [size] = useState(pageSize || 10);

  const pageNo = useMemo(() => {
    if (total > 0 && currentTotal >= total) {
      return null;
    }
    return Math.floor(currentTotal / size) + 1;
  }, [currentTotal, size, total]);

  return {
    pageNo,
    pageSize,
    total,
    setTotal,
  };
}
