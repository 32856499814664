import React from 'react';
import { AnalyticalTable as AnalyticalTableWCR } from '@ui5/webcomponents-react';
import { Spinner, useTranslation } from 'src/common/eureka';

const DefaultLoadingComponent = () => (
  <div
    style={{
      textAlign: 'center',
      height: 0,
      marginTop: '-15px',
    }}
  >
    <Spinner />
  </div>
);

const DefaultNoDataComponent = ({ className, style }) => {
  const { t } = useTranslation();
  return (
    <div className={className} style={{ ...style, width: '100%' }}>
      {t('No Data')}
    </div>
  );
};

const AnalyticalTable = ({
  children,
  loading,
  LoadingComponent = DefaultLoadingComponent,
  NoDataComponent = DefaultNoDataComponent,
  ...others
}) => {
  return (
    <>
      <AnalyticalTableWCR NoDataComponent={(props) => <NoDataComponent {...props} />} {...others}>
        {children}
      </AnalyticalTableWCR>
      {loading && <LoadingComponent />}
    </>
  );
};
export default AnalyticalTable;
