import React from 'react';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { Spinner: UISpinner } = eureka.components;
const { MessageToast } = eureka.controls;
const useTranslation = () => ({ t: (s) => s });
const DateManager = eureka.DateManager;
const eventBus = eurekaMgrs.eventBus;
const ConfigManager = eurekaMgrs.ConfigManager;
const { getFeatureToggle, getConfig } = ConfigManager;

export {
  MessageToast,
  useTranslation,
  eventBus,
  ConfigManager,
  DateManager,
  getConfig,
  getFeatureToggle,
};

export function Spinner(props) {
  return <UISpinner delay={100} {...props} />;
}
