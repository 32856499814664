import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  DynamicPage,
  DynamicPageTitle,
  DynamicPageHeader,
  ThemeProvider,
  TableSelectionMode,
  Label,
  Button,
  ButtonDesign,
  RatingIndicator,
  FlexBox,
} from '@ui5/webcomponents-react';
import AnalyticalTable from 'src/features/common/AnalyticalTable';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'src/common/eureka';
import { titleStyle, analyticTableStyle } from 'src/features/common/TableStyles.js';
import { fetchConsentProductList, checkReviewed } from './axios';
import AuthDialog from './AuthDialog';
import ReviewDialog from './ReviewDialog';

const styles = () => ({
  usersTable: analyticTableStyle(),
});

const useStyles = createUseStyles(styles);

export default (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const authDialogRef = useRef();
  const reviewDialogRef = useRef();
  const { search } = useLocation();
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState({});

  let user = new URLSearchParams(search).get('user');
  if (user && user.indexOf('@sap.com') === -1) {
    user = user + '@sap.com';
  }
  const [userId, setUserId] = useState(user);
  const [hasReviewed, setHasReviewed] = useState(false);

  const reviews = productInfo.reviews || [];

  const refreshProductInfo = () => {
    fetchConsentProductList().then((res) => {
      const product = res.data?.find((item) => item.name === id);
      setProductInfo(product);
    });
  };

  useEffect(() => {
    refreshProductInfo();
    if (user) {
      checkReviewed(user).then((res) => {
        setHasReviewed(res.data);
      });
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('Rating'),
        accessor: 'rating',
        Cell: ({ row }) => <RatingIndicator value={row.original.rating} readonly />,
      },
      {
        Header: t('Review Date'),
        accessor: 'createOn',
      },
      {
        Header: t('Create By'),
        accessor: 'createBy',
      },
      {
        Header: t('Title'),
        accessor: 'title',
      },
      {
        Header: t('Review Text'),
        accessor: 'text',
      },
    ],
    [t],
  );

  return (
    <ThemeProvider withToastContainer>
      <DynamicPage
        headerTitle={<DynamicPageTitle header={productInfo.name} />}
        headerContent={
          <DynamicPageHeader>
            <FlexBox style={{ lineHeight: '2' }}>
              <div style={{ flex: 'none' }}>
                <img
                  height={100}
                  style={{ marginRight: '10px' }}
                  src={productInfo.imageUrl}
                  alt=""
                />
              </div>
              <div style={{ width: '30%' }}>
                <div>
                  <Label showColon>{t('Product Category')}</Label>
                  <span>{productInfo.category}</span>
                </div>
                <div>
                  <Label showColon>{t('Supplier')}</Label>
                  <span>{productInfo.supplier}</span>
                </div>
              </div>
              <div style={{ width: '30%' }}>
                <div>
                  <Label showColon>{t('Rating')}</Label>
                  <span>{productInfo.rating}</span>
                </div>
                <div>
                  <Label showColon>{t('Price')}</Label>
                  <span>{productInfo.price}</span>
                </div>
              </div>
            </FlexBox>
          </DynamicPageHeader>
        }
        showHideHeaderButton={false}
        headerContentPinnable={false}
      >
        <FlexBox justifyContent="SpaceBetween" alignItems="Center" style={{ marginBottom: '10px' }}>
          <div style={titleStyle}>
            {t('Reviews')} ({reviews.length})
          </div>
          <Button
            design={ButtonDesign.Transparent}
            onClick={() => {
              if (!user) {
                setUserId(`${Math.random().toString().slice(2, 10)}@sap.com`);
              }
              if (hasReviewed) {
                reviewDialogRef.current.show();
              } else {
                authDialogRef.current.show();
              }
            }}
          >
            {t('Create Review')}
          </Button>
        </FlexBox>
        <AnalyticalTable
          className={classes.usersTable}
          data={reviews}
          columns={columns}
          sortable={false}
          minRows={reviews.length === 0 ? 5 : reviews.length}
          selectionMode={TableSelectionMode.None}
        />
      </DynamicPage>
      <AuthDialog
        ref={authDialogRef}
        userId={userId}
        onOk={() => {
          if (user) {
            setHasReviewed(true);
          }
          authDialogRef.current.close();
          reviewDialogRef.current.show();
        }}
        onClose={() => authDialogRef.current.close()}
      />
      <ReviewDialog
        ref={reviewDialogRef}
        userId={userId}
        productInfo={productInfo}
        onOk={() => {
          reviewDialogRef.current.close();
          refreshProductInfo();
        }}
        onClose={() => reviewDialogRef.current.close()}
      />
    </ThemeProvider>
  );
};
