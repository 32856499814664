import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DynamicPage,
  DynamicPageTitle,
  ThemeProvider,
  TableSelectionMode,
  RatingIndicator,
} from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';
import { Spinner, useTranslation } from 'src/common/eureka';
import AnalyticalTable from 'src/features/common/AnalyticalTable';
import useList from 'src/features/common/hooks/useList';
import { titleStyle, analyticTableStyle } from 'src/features/common/TableStyles.js';
import { fetchConsentProductList } from './axios';

const styles = () => ({
  usersTable: analyticTableStyle(),
});

const useStyles = createUseStyles(styles);

export default (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const {
    data,
    doFetch,
    isLoading,
    isLoadingMore,
    onLoadMore,
    pagination: { total: totalCount },
  } = useList(fetchConsentProductList, { pageSize: 30 });

  const columns = useMemo(
    () => [
      {
        Header: t('Image'),
        accessor: 'imageUrl',
        Cell: ({ row }) => {
          const { imageUrl } = row.original;
          return <img height={50} src={imageUrl} alt="" />;
        },
      },
      {
        Header: t('Product Name'),
        accessor: 'name',
      },
      {
        Header: t('Product Category'),
        accessor: 'category',
      },
      {
        Header: t('Supplier'),
        accessor: 'supplier',
      },
      {
        Header: t('Rating'),
        accessor: 'rating',
        Cell: ({ row }) => <RatingIndicator value={row.original.rating} readonly />,
      },
      {
        Header: t('Price'),
        accessor: 'price',
      },
    ],
    [t],
  );

  const handleRowClick = useCallback(
    (e) => {
      const row = e.detail.row.original;
      history.push(`/consent/product/${row.name}`);
    },
    [history],
  );

  return (
    <ThemeProvider withToastContainer>
      <DynamicPage
        headerTitle={<DynamicPageTitle header={t('Product Ratings')} />}
        showHideHeaderButton={false}
        headerContentPinnable={false}
      >
        <div className="section-header">
          <div style={titleStyle}>
            {t('Products')} ({totalCount})
          </div>
        </div>
        {!isLoading && (
          <>
            <AnalyticalTable
              id="rm-alert-rule-table"
              className={classes.usersTable}
              rowHeight={65}
              data={data}
              columns={columns}
              sortable={false}
              minRows={data.length === 0 ? 5 : data.length}
              visibleRows={5}
              infiniteScroll
              infiniteScrollThreshold={11}
              loading={isLoadingMore}
              onLoadMore={onLoadMore}
              selectionMode={TableSelectionMode.NONE}
              onRowClick={handleRowClick}
            />
          </>
        )}
        {isLoading && <Spinner className="spinner" />}
      </DynamicPage>
    </ThemeProvider>
  );
};
