/* eslint-disable react/jsx-filename-extension */
import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets.js';
import '@ui5/webcomponents/dist/Assets.js';

ReactDOM.render(<App />, document.getElementById('eureka-app'));
